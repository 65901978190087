import axios from 'axios'
import { BASE_URL } from '../config/api'

export const instance = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

export function get(url, config) {
  return instance
    .get(url, config)
    .then(response => response)
    .catch(error => {
      if (error && error.response) {
        const res = { code: error?.response?.status, msg: error?.response?.data, error }
        throw res
      } else {
        const res = { code: 500, msg: error.message, error }
        throw res
      }
    })
}
