import colors from '../assets/colors'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Helvetica'
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0 0 15px 0;
  }

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const theme = {
  colors,
}
