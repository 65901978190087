import { URL_ATTRIBUTE } from '../../../config/api'

export const checkCreative = creativities => {
  const formatCreativities = []
  const dayOfWeek = new Date().getDay()
  const dayOfMonth = new Date().getDate()
  const year = new Date().getFullYear()
  const month = new Date().getMonth() + 1

  let formatDate = `${year}-${month}-${dayOfMonth}`
  formatDate = Date.parse(formatDate)

  creativities?.forEach(creativity => {
    let { start: creativityStart, end: creativityEnd } = creativity
    creativityStart = Date.parse(creativityStart)
    creativityEnd = Date.parse(creativityEnd)

    if (creativity?.active && creativity[URL_ATTRIBUTE] && formatDate >= creativityStart && formatDate <= creativityEnd) {
      creativity?.timeRanges?.forEach(timeRange => {
        if (timeRange?.weekDay?.includes(dayOfWeek)) {
          const hourStart = timeRange.start.split(':')[0]
          const minuteStart = timeRange.start.split(':')[1]
          const hourEnd = timeRange.end.split(':')[0]
          const minuteEnd = timeRange.end.split(':')[1]

          const now = new Date()
          const end = new Date()
          const start = new Date()

          end.setHours(hourEnd)
          end.setMinutes(minuteEnd)
          start.setHours(hourStart)
          start.setMinutes(minuteStart)

          if (now > start && now < end) {
            formatCreativities.push(creativity)
          }
        }
      })
    }
  })

  return formatCreativities
}
