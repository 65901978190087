import React from 'react'
import Routes from '../routes'
import { ThemeProvider } from 'styled-components'
import { theme as styledTheme, GlobalStyle } from '../../../config/styledTheme'

const App = () => (
  <ThemeProvider theme={styledTheme}>
    <GlobalStyle />
    <Routes />
  </ThemeProvider>
)

export default App
