import React from 'react'
import { Home } from '../../pages'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={Home} exact path='*' />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
