import styled from 'styled-components'

const ComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.black};

  .creativitie-wrapper {
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    video {
        width: 100%;
    }
  }

  .creativitie-wrapper.right video {
    height: 1080px;
    width: unset
  }
  
 .creativitie-wrapper.left video {
    height: 1080px;
    width: unset
  }
  
  .creativitie-wrapper.top video {
    width: 1080px;
  }
  
  .creativitie-wrapper.right img {
    height: 1080px;
    width: unset
  }
  
 .creativitie-wrapper.left img {
    height: 1080px;
    width: unset
  }
  
  .creativitie-wrapper.top img {
    width: 1080px;
  }
`

export default ComponentStyled
