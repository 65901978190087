import { get } from './utils'

export const getCampaigns = () => {
  const url = `/public`
  return get(url)
}

export const getParkingCampaigns = id => {
  const url = `/public/v2/parking/${id}`
  return get(url)
}
